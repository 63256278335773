<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

	export default {
		extends: BaseVue,
		data() {
			 return {}
		},
		computed: {

		},
		async mounted() {
			this.$set(this.$root, 'page', this)
		},
		methods: {
		},
	};
</script>
<template>
  <section id="content">
      <div class="content-wrap py-0">
        <section class="section bg-light-blue nomargin not-found">
          <div class="taste-comp right"></div>
          <div class="taste-comp left"></div>
          <div class="container">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-9">
                      <div class="row align-items-center">
                        <div class="col-md-4">
                          <img :src="assets('fo_images','/glass-404.png')" alt="Page Not Found" class="not-found__img">
                        </div>
                        <div class="col-md-8">
                          <div class="heading-block noborder">
                            <h2 class="not-found__title">{{web.lbl_whoops}}</h2>
                          </div>
                          <a href="https://www.nutrimart.co.id/brands/hilo.html" target="_blank" rel="noopener noreferrer" class="cta cta-primary cta--reveal cta--longer">
                            <span>{{web.lbl_by_now}}</span>
                            <i class="icon-line-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                      <div class="not-found-refer">
                        <h4 class="not-found-refer__title" v-html="web.lbl_404_desc"></h4>
                        <div class="not-found-refer-links">
                          <router-link :to="{name:'Index'}" class="not-found-refer-links__link">{{web.menu_home}}</router-link>
                          <router-link :to="{name:'Product'}" class="not-found-refer-links__link">{{web.menu_all_product}}</router-link>
                          <router-link :to="{name:'HealthInfo'}" class="not-found-refer-links__link">{{web.menu_health_info}}</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </section>
      </div>
    </section>
</template>